import React from "react";
import type { HeadFC } from "gatsby";
import { MesaBoogie } from "../../screens/OverviewScreen";

const MesaBoogieBrandPage = () => {
    return <MesaBoogie />;
};

export default MesaBoogieBrandPage;

export const Head: HeadFC = () => <title>Our Brands</title>;
